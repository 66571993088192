import React from 'react'
import signup from '../images/Signup.png'
import logo from '../images/logo.png'
import fb from '../images/fb.png'
import google from '../images/google.png'
import twitter from '../images/twitter.png'
import rabbit from '../images/rabbit.png'
import { Link, useNavigate } from 'react-router-dom'
import {app} from '../firebase'
import { useState , useEffect } from 'react'
import {RotatingTriangles} from  'react-loader-spinner'
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { useLocation } from 'react-router-dom'
import axios from 'axios'
const Signup = () => {
  const [name, setName]=useState('')
  const [email, setEmail]=useState('')
  const [pass, setPass]=useState('')
  const [otp, setOtp]=useState('')
  const [loader, setLoader] = useState(false)
  const [age, setAge] = useState('')
  const [language, setLanguage]=useState('')
  const [open, setOpen]= useState(false)

  const navigate = useNavigate()

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let query = useQuery()

console.log(query.get('msisdn'))

  useEffect(()=>{
    if(localStorage.getItem('language')){
      setLanguage(localStorage.getItem('language'))
    }
    else{
      setLanguage('english')
    }
  },[])
  const provider = new GoogleAuthProvider();
const auth = getAuth(app);
auth.languageCode = 'it';

useEffect(()=>{
  setLoader(true)
  if(localStorage.getItem('language')){
    setLanguage(localStorage.getItem('language'))
  }
  else{
    setLanguage('english')
  }
  if(localStorage.getItem('userId')){
    navigate('/home')
  }
  else{

    getRedirectResult(auth)
  .then((result) => {
    setLoader(true)
    
    // This gives you a Google Access Token. You can use it to access the Google API.
    console.log(result)
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.log(user)
    console.log('Token: '+ token)
    axios.post('https://highfivesgames.com/users/sociallogin',{
      type:'google',
      name:user.displayName,
      email:user.email,
      uid:user.uid,
    },{
      headers:{
        'x-operator':query.get('op')
      }
    })
    .then((response)=>{
      if(response.status !=200){
        axios.post('https://highfivesgames.com/users/socialReg',{
          type:'google',
          name:user.displayName,
          email:user.email,
          uid:user.uid,
        },{
          headers:{
            'x-operator':query.get('op')
          }
        }).then((response)=>{
          console.log(response);
          localStorage.setItem('userId',response.data.id)
          navigate('/home')
        })
      }
      else{
        console.log(response)
        // setLoader(false)
        localStorage.setItem('userId',response.data.user.id)
        localStorage.setItem('sessionId',response.data.user.session)
        navigate('/home')
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }).catch((error) => {
    setLoader(false)
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(error)
    // ...
  });
  }
  
},[])

const googleLogin =  (e)=>{
    e.preventDefault();
     signInWithRedirect(auth, provider)
     setLoader(true);
  }


  const handleSubmit = (e) => {

    e.preventDefault()
    console.log(name, email, age, pass, otp)
    axios.post('https://highfivesgames.com/users',{
      name:name,
      email:email,
      age:parseInt(age),
      password:pass,
      otp:otp,
      avatar:"",
      status:""
  },{
    headers:{
      'x-operator':query.get('op')
    }
  })
  .then(response =>{
    localStorage.setItem('userId',response.data.id)
    setName('')
    setEmail('')
    setAge('')
    setPass('')
    setOtp('')
    navigate('/home')
  })
  .catch(error =>{
    setName('')
    setEmail('')
    setAge('')
    setPass('')
    setOtp('')
    if(error.code == 409){
      alert('User already exists')
    }
    else{
      alert('Wrong Otp')
    }
  })
    
  }
  const geneOtp = (e) =>{
    e.preventDefault()
    axios.post('https://highfivesgames.com/users/otp',{
      email:email
    },{
      headers:{
        'x-operator':query.get('op')
      }
    })
    .then((response) =>{
      console.log(response)
      alert('OTP Sent successfully')
    })
    .catch((error) =>{
      console.log(error)
      alert('User already exists')
    })
  }
  return (
    <div>
      {
        loader?
        <div>
           <div className="flex h-screen w-full justify-center items-center">
                    <RotatingTriangles></RotatingTriangles>
                    </div>
        </div>
        :
    <div className="font-poppins max-w-[30rem]  w-screen mx-auto overflow-cip  h-screen  bg-[#2C3F66]">
        <div className="flex justify-between items-center px-2">
          <img src={logo} className="h-16  pt-2"></img>
          <div className="text-white mr-5 relative">
            <button onClick={()=>{setOpen(!open)}}>
            Language : <br></br> {language}
            </button>
            {
              open &&
            <div className="flex flex-col bg-white text-black px-3 py-2 w-full rounded-xl absolute">
              <button onClick={()=>{
                setLanguage('arabic')
                localStorage.setItem('language','arabic')
              }}>
                <p>عربي</p>
                </button>
                <button onClick={()=>{
                setLanguage('english')
                localStorage.setItem('language','english')
              }}>
                <p>English</p>
                </button>
            </div>
            }
          </div>
          </div>
        <img src={signup} className="mx-auto h-[16rem] -mt-10"></img>
        <div className="bg-[#8CC6AB] overflow- h-full skew-y-[18deg] mt-16 ">
          <div className="bg-white rounded-3xl shadow-2xl -skew-y-[18deg] -mt-12 px-6 py-4 mx-6">
            <h1 className="text-[#2C3F66] text-center ">
            {
                language == 'english'
                ?
              'Sign Up'
              :
              'اشترك'
              }
            </h1>
            <form className="mt-6">
              <div className="flex items-center justify-evenly space-x-4  w-full">
                <img src={rabbit} className="h-12 rounded-lg"></img>
                <div>
                    <label>
                    {
                language == 'english'
                ?
              'Name'
              :
              'اسم'
              }
                      </label><br></br>
                    <input className="my-2 w-full border-b-2 outline-none pb-1" value={name} onChange={(e)=>{setName(e.target.value)}}></input>
                </div>
                    <select name="age" id="lang" value={age} onChange={(e)=>{setAge(e.target.value)}} className="bg-orange-400 mx-auto text-white outline-none p-1 rounded-xl w-/6">
        <option value="">
        {
                language == 'english'
                ?
              'Age'
              :
              'عمر'
              }
        </option>
        <option value="3">
        {
                language == 'english'
                ?
              '3'
              :
              'ثلاثة'
              }
        </option>
        <option value="4">
        {
                language == 'english'
                ?
              '4'
              :
              'أربعة'
              }
        </option>
        <option value="5">
        {
                language == 'english'
                ?
              '5'
              :
              'خمسة'
              }
        </option>
        <option value="6">
        {
                language == 'english'
                ?
              '6'
              :
              'ستة'
              }
        </option>
        <option value="7">
        {
                language == 'english'
                ?
              '7'
              :
              'سبعة'
              }
        </option>
        <option value="8">
        {
                language == 'english'
                ?
              '8'
              :
              'ثمانية'
              }
        </option>
        <option value="9">
        {
                language == 'english'
                ?
              '9'
              :
              'تسعة'
              }
        </option>
        <option value="10">
        {
                language == 'english'
                ?
              '10'
              :
              'عشرة'
              }
        </option>
        <option value="11">
        {
                language == 'english'
                ?
              '11'
              :
              'إحدى عشر'
              }
        </option>
        <option value="12">
        {
                language == 'english'
                ?
              '12'
              :
              'إثنا عشر'
              }
        </option>
        <option value="12+">
        {
                language == 'english'
                ?
              '12'
              :
              'إثنا عشر'
              }+</option>
      </select>
              </div>
              <label>
              {
                language == 'english'
                ?
              'Email'
              :
              'بريد إلكتروني'
              }</label><br></br>
              <div className="flex items-end space-x-4 w-full">
              <input className="my-2 w-full border-b-2 outline-none pb-1" value={email} onChange={(e)=>{setEmail(e.target.value)}}></input>
              <button onClick={geneOtp} className="bg-[#2C3F66] text-white rounded-xl p-3">
              {
                language == 'english'
                ?
              'Generate OTP'
              :
              'OTP بنائیں'
              }
              </button>
              </div>
              <label className="mt-2">
              {
                language == 'english'
                ?
              'Password'
              :
              'كلمة المرور'
              }
              </label><br></br>
              <input className="my-2 w-full border-b-2 outline-none pb-1" type="password" value={pass} onChange={(e)=>{setPass(e.target.value)}}></input>
              <label className="mt-2">
              {
                language == 'english'
                ?
              'Enter OTP'
              :
              'أدخل OTP'
              }
                </label><br></br>
              <input className="my-2 w-full border-b-2 outline-none pb-1" type="text" value={otp} onChange={(e)=>{setOtp(e.target.value)}}></input>
              {/* <h1 className="text-right text-[#1991EB] mb-3 text-sm">Forgot Password?</h1> */}
              <div className="flex justify-between">
                <div className="flex space-x-4">
                  <button onClick={googleLogin}>
                  <img src={google} ></img>
                  </button>
                </div>
                <button className="bg-[#2C3F66] text-white px-10 -mr-6 -mb-4 rounded-l-3xl rounded-br-3xl " onClick={handleSubmit}>
                {
                language == 'english'
                ?
              'Sign Up'
              :
              'اشترك'
              }
                  </button>
              </div>
            </form>
          </div>
            <h1 className="-skew-y-[18deg] mt-4 text-white text-center">
              {
                language == "english" ?
                "Login as a Guest"
                :
                "تسجيل الدخول كضيف"
              }
            </h1>
            <Link to="/">
            <h1 className="text-center mt-4 text-white -skew-y-[18deg] ">
            {
                language == "english" ?
                "Already have an account? Sign in now"
                :
                "هل لديك حساب؟ قم بتسجيل دخولك الآن"
              }
            </h1>
            </Link>
        </div>
        <div className="w-screen max-w-[30rem] py-20 -mt-28 bg-[#8CC6AB]"></div>
    </div>
      }
    </div>
  )
}

export default Signup