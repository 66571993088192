import React, {useState, useEffect} from 'react'
import First from '../components/First'
import Second from '../components/Second'
import Third from '../components/Third'
import { Link, useNavigate } from "react-router-dom";

const Carousel = () => {
    const history = useNavigate();
    const [index, setIndex] = useState(0)
    const classnames1 = () => {
        if(index ===0){
            return("px-2 py-1 rounded-xl bg-[#FE6F5F] ")
        }
        else return('px-1 py-1 bg-white rounded-xl')
    }
    const classnames2 = () => {
        if(index ===1){
            return("px-2 py-1 rounded-xl bg-[#FE6F5F] ")
        }
        else return('px-1 py-1 bg-white rounded-xl')
    }
    const classnames3 = () => {
        if(index ===2){
            return("px-2 py-1 rounded-xl bg-[#FE6F5F] ")
        }
        else return('px-1 py-1 bg-white rounded-xl')
    }
    const out1 = classnames1()
    const out2 = classnames2()
    const out3 = classnames3()

    if(index>2){
        history('/home')
    }


    return (
    <div className="font-poppins max-w-[30rem] bg-[#2C3F66] w-screen mx-auto overflow-hidden h-screen  ">
        {
            index === 0 &&
            <First/>    
        }
        {
            index === 1 &&
            <Second/>
        }
        {
            index === 2 &&
            <div className="overflow-clip">
                <Third/>
            </div>
        }
        <div className="flex justify-between w-screen max-w-[30rem] absolute items-center my-3 px-3 bottom-0">
            <Link to="/home"><h1 className="text-white ">Skip</h1></Link>
            <div className="flex space-x-2 items-center">
                <button className={out1} onClick={()=>{setIndex(0)}}></button>
                <button className={out2} onClick={()=>{setIndex(1)}}></button>
                <button className={out3} onClick={()=>{setIndex(2)}}></button>
            </div>
            <button onClick={()=>{setIndex(index+1)}} className="bg-[#FE6F5F] text-white rounded-3xl py-3 px-4">Next</button>
        </div>
    </div>
  )
}

export default Carousel