import './App.css';
import {BrowserRouter as Router,Routes,Route, HashRouter} from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Carousel from './pages/Carousel';
import Subscribe from './pages/Subscribe';
import Howtoplay from './pages/Howtoplay';
import GetOtp from './pages/GetOtp';
import Iframe from './pages/Iframe';
import ALogin from './pages/Alogin';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import Video from './pages/Video';
import HomeMsisdn from './pages/HomeMsisdn'
import mixpanel from 'mixpanel-browser';
import Expired from './pages/Expired';
function App() {
  
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomeMsisdn/>} ></Route>
        <Route path="/:serviceName/:merchantName/:key/:success/:msisdn" element={<Video/>} exact></Route>
        <Route path="/expired" element={<Expired/>}></Route>
        <Route path="/signup" element={<Signup/>}></Route>
        <Route path="/start" element={<Carousel/>}></Route>
        <Route path="/subscribe" element={<Subscribe/>}></Route>
        <Route path="/howtoplay" element={<Howtoplay/>}></Route>
        <Route path="/home" element={<Home></Home>}></Route>
        <Route path="/getOtp" element={<GetOtp/>}></Route>
        <Route path="/iframe" element={<Iframe/>}></Route>
        <Route path="/loginApple" element={<ALogin/>}></Route>
        <Route path="/privacy" element={<Privacy/>}></Route>
        <Route path="/terms" element={<Terms/>}></Route> 
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
