import React from 'react'
import { useNavigate } from 'react-router-dom'

const Expired = () => {
  const navigate = useNavigate()

  return (
    <div className="font-poppins max-w-[30rem]  w-screen mx-auto flex justify-center items-center text-white h-screen  bg-[#2C3F66]">
      <div>
        <p>The session has expired, please renew your subscription.</p>
        <div className="text-center">
          <button className='bg-[#FF6356] text-white px-5 rounded-lg my-5 py-2' onClick={()=>{
            localStorage.clear()
            navigate('/login')
          }}>Go to Login Page</button>
        </div>
      </div>

    </div>
  )
}

export default Expired