import React from 'react'
import gift from '../images/gift.png'
import c1 from '../images/c1.png'
const Third = () => {
  return (
    <div>
        <img src={c1} className="w-full -pt-40"></img>
        <img src={gift} className="h-80 mx-auto -mt-72"></img>
        <div className="mt-12 text-white mx-4">
        <h1 className="text-2xl my-3 font-bold">Choose your reward</h1>
        <p className="">Explore awesome gifts on our Rewards Mall. Redeem Donuts for a reward of your choice!</p>
        </div>
    </div>
    // <div className="">
    //     <img src={gift} className="mx-auto  h-80 z-20 pt-20"></img>
    //     <div className="bg-[#DEB688] h-screen   w-full max-w-[30rem] -skew-y-[18deg]  -mt-16 ">
    //         <div className="skew-y-[18deg]  mx-3 mt-40 text-white ">
    //             <h1 className="text-2xl font-bold">Choose your reward</h1>
    //             <p className="text-xl">Explore awesome gifts on our Rewards Mall. Redeem Donuts for a reward of your choice!</p>
    //         </div>
    //     </div>
    //     {/* <div className="bg-[#DEB688] py-8 "></div> */}
    // </div>
  )
}

export default Third