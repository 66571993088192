import React,{useState} from 'react'
import signup from '../images/Signup.png'
import logo from '../images/logo.png'
import fb from '../images/fb.png'
import google from '../images/google.png'
import twitter from '../images/twitter.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
const GetOtp = () => {

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let query = useQuery()

console.log(query.get('msisdn'))

    let navigate = useNavigate();
    const [email, setEmail] = useState('') 


    const handleClick = ()=>{
        console.log(email)
        axios.post('https://highfivesgames.com/users/otp',{
            email:email
        }, {
          'x-operator':query.get('op')
        })
        .then((response)=>{
          console.log(response)
          if(response.status == 200){
            navigate("/signup");
          }
          else{
            alert('Email id already exists')
          }
        })
        .catch((error)=>{console.log(error)})
        setEmail('')
    }

  return (
    <div className="font-poppins max-w-[30rem]  w-screen mx-auto overflow-cip  h-screen  bg-[#2C3F66]">
        <img src={logo} className="h-16 pl-2 pt-2"></img>
        <img src={signup} className="mx-auto h-[16rem] -mt-10"></img>
        <div className="bg-[#8CC6AB] overflow-  h-full skew-y-[18deg] mt-32 ">
        <div className="bg-white rounded-3xl shadow-3xl -skew-y-[18deg] -mt-12 px-6 py-4 mx-6">
            <h1 className="text-[#2C3F66] text-center ">Sign in to your account</h1>
            <form className="mt-6">
              <label className="mt-1">Your Email</label><br></br>
              <input className="my-2 w-full border-b-2 outline-none pb-1 " type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}></input><br></br>
              <div className="flex justify-between">
                <div className="flex space-x-4">
                  <img src={fb} ></img>
                  <img src={twitter} ></img>
                  <img src={google} ></img>
                </div>
                <button  onClick={handleClick} className="bg-[#2C3F66] text-white px-10 -mr-6 -mb-4 rounded-l-3xl rounded-br-3xl "> 
                Get Otp
                </button>
          
              </div>
            </form>
          </div>
            <h1 className="-skew-y-[18deg] mt-4 text-white text-center">Login as a Guest</h1>
            <Link to="/login">
            <h1 className="text-center mt-4 text-white -skew-y-[18deg] ">Already have an account? Sign in now</h1>
            </Link>
        </div>
        <div className="w-screen max-w-[30rem] py-20 -mt-28 bg-[#8CC6AB]"></div>
    </div>
  )
}

export default GetOtp