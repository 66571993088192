import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import home from '../images/home.png'
import {RotatingTriangles} from  'react-loader-spinner'
const Iframe = () => {
  const location = useLocation()
  const { index } = location.state
  const[games, setGames] = useState([])
    const [loader, setLoader] = useState(true)
    const uid  = window.localStorage.getItem('userId')
    const [name, setName] = useState('')
    
    useEffect(() =>{
      axios.get(`https://highfivesgames.com/users/${uid}`)
    .then((response)=>{
        // setisLoading(false)
        setName(response.data.user.name)
        console.log(response)
        // setName(response.data.user.name)
    })
    .catch((err)=>{console.log(err)})
       
    },[])

    useEffect(()=>{
      axios.get('https://highfivesgames.com/games/')
      .then((response)=>{
          setGames(response.data.games)
          setLoader(false)
          // console.log(games[index].game_url)
      })
      .catch((err)=>{console.log(err)})
    },[])


  return (
    <div>
      {
        loader
        ?
        <div className="font-poppins max-w-[30rem] w-screen mx-auto h-screen  bg-[#2C3F66]">
          <div className="flex h-screen w-full justify-center items-center">
            <RotatingTriangles></RotatingTriangles>
          </div>
        </div>
        :
        <div className="relative">
          <iframe src={`https://highfivesgames.com/${games[index].game_url}?playerInputSecond=${uid}&playerIdName=${name}&appGameId=${games[index].id}&language=${localStorage.getItem('language')}`} className="max-w-[30rem] mx-auto h-screen w-screen "></iframe>
          {/* <Link to="/home"> 
          <div className="absolute bottom-0 w-screen max-w-[30rem] bg-yellow-500  left-1/2 -translate-x-1/2 ">
            <img src={home} className="mx-auto h-10"></img>
          </div>
          </Link> */}
        </div>
      }
    </div>
  )
}

export default Iframe