import React from 'react'
import c1 from '../images/c1.png'
import play from '../images/play.png'

const First = () => {
  return (
    <div>
        <img src={c1} className="w-full -pt-40"></img>
        <img src={play} className="h-80 mx-auto -mt-72"></img>
        <div className="mt-12 text-white mx-4">
        <h1 className="text-2xl my-3 font-bold">Play fun games!</h1>
        <p className="">Have fun playing solo or with friends and family.</p>
        </div>
    </div>
  )
}

export default First