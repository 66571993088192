import React from 'react'
import c2 from '../images/c2.png'
import dp from '../images/dp.png'
const Second = () => {
  return (
    <div>
        <img src={c2} className="w-full  -pt-40"></img>
        <div className="relative">
        <img src={dp} className="h-[30rem] absolute right-0  -mt-96 " ></img>
        </div>
        <div className="mt-12 text-white mx-4">
        <h1 className="text-2xl my-3 font-bold">Collect Donut Points</h1>
        <p className="">Subscribed users collect Donuts while playing games. Redeem Donuts for awesome rewards!</p>
        </div>
    </div>
  )
}

export default Second