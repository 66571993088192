import React, {useState, useEffect} from 'react'
import Podium from '../components/Podium'
import ScoreboardAllTime from '../components/ScoreboardAllTime'

const Leaderboard = (props) => {
  // console.log(props.list)
  const [allTime, setAllTime] = useState(0)

  const [language, setLanguage] = useState('')

    useEffect(()=>{
        if(localStorage.getItem('language')){
            setLanguage(localStorage.getItem('language'))
          }
          else{
            setLanguage('english')
          }
    },[localStorage.getItem('language')])
   
  return (
    <div>
        <div className="sticky top-0 z-10 bg-[#2c3f66]">
       <h1 className='text-white px-4 text-center text-2xl py-3'>{
        language == "english"?
        "Leaderboard"
        :
        "ليدربورد"
       }</h1> 
       <div className='grid grid-cols-2 text-center  rounded-xl mt-4 text-white items-center py-1 px-1 bg-[#40468B] mx-4'>
           <button onClick={()=>{setAllTime(0)}}>
            <div className={allTime ? 'rounded-xl  py-2':'rounded-xl bg-[#FF6356] py-2'}>
                <h1 className='text-xl'>{
        language == "english"?
        "Weekly"
        :
        "أسبوعي"
       }</h1>
            </div>
            </button> 
            <button onClick={()=>{setAllTime(1)}}>
            <div className={allTime ? 'rounded-xl bg-[#FF6356]  py-2':'rounded-xl  py-2'}>
                <h1 className='text-xl'>
                {
        language == "english"?
        "Monthly"
        :
        "شهريا"
       }
                </h1>
            </div>
            </button>
        </div>
        </div>
        <div className="relative">
            {
                allTime === 0 && 
               <div>
                <Podium list={props.list}/>
               </div> 
            }
            {
                allTime === 1 &&
                <div>
                    <ScoreboardAllTime list={props.list}/>
                </div>
            }
        </div>
    </div>
  )
}

export default Leaderboard