import React from 'react'
import hourglass from '../images/hourglass.png'
import rocket from '../images/rocket.png'
import pig from '../images/pig.png'
import GooglePayButton from '@google-pay/button-react'
const Subscribe = () => {
  return (
    <div className="font-poppins max-w-[30rem]  w-screen mx-auto     bg-[#2C3F66]">
        <h1 className="text-center pt-4 text-xl absolute top-0 mx-auto w-full left-0 right-0 text-white">Go Premium</h1>
        <div className="flex flex-col h-screen align-center justify-center min-h-screen">        
       <div className="flex mt-4 px-2 py-2 items-center space-x-2 bg-white mx-8 rounded-xl">
        <img src={hourglass} className="h-28"></img>
        <div className="flex items-start justify-between  w-full">
            <div className="space-y-1">
                <h1 className="font-bold underline-offset-2 ">Monthly</h1>
                <h1>Rs 69</h1>
                <h1 className="invisible">s</h1>
                {/* <h1 className="bg-[#FF6356] rounded-xl px-3 text-white">Most Purchased</h1> */}
            </div>
            <GooglePayButton
    environment="TEST"
    paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
        {
            type: 'CARD',
            parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
                gateway: 'example',
                gatewayMerchantId: 'exampleGatewayMerchantId',
            },
            },
        },
        ],
        merchantInfo: {
        merchantId: '665717561011426458',
        merchantName: 'HighFives Game',
        },
        transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: '69.00',
        currencyCode: 'INR',
        countryCode: 'IN',
        },
    }}
    onLoadPaymentData={paymentRequest => {
        console.log('load payment data', paymentRequest);
    }}
    buttonColor ={'#FF6356'}
    buttonType={'short'}
    buttonSizeMode={'fill'}

    className="w-1/2"
    />
        </div>

       </div>

       

       <div className="flex mt-4 px-2 py-2 items-center space-x-2 bg-white mx-8 rounded-xl">
        <img src={hourglass} className="h-28"></img>
        <div className="flex items-start justify-between  w-full">
            <div className="space-y-1">
                <h1 className="font-bold underline-offset-2 ">6 Months</h1>
                <h1>Rs 349</h1>
                <h1>Save 15%</h1>
                {/* <h1 className="bg-[#FF6356] rounded-xl px-3 text-white">Most Purchased</h1> */}
            </div>
            <GooglePayButton
    environment="PRODUCTION"
    paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
        {
            type: 'CARD',
            parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
                gateway: 'example',
                gatewayMerchantId: '665717561011426458',
            },
            },
        },
        ],
        merchantInfo: {
        merchantId: '665717561011426458',
        merchantName: 'HighFives Game',
        },
        transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: '349.00',
        currencyCode:'INR',
        countryCode: 'IN',
        },
    }}
    onLoadPaymentData={paymentRequest => {
        console.log('load payment data', paymentRequest);
    }}
    buttonColor ={'#FF6356'}
    buttonType={'short'}
    buttonSizeMode={'fill'}

    className="w-1/2"
    />
        </div>
        
       </div>

       <div className="flex px-2 py-2 items-center space-x-2 mt-5  bg-white mx-8 rounded-xl">
        <img src={pig} className="h-28"></img>
        <div className="flex items-start justify-between  w-full">
            <div className="space-y-1">
                <h1 className="font-bold underline-offset-2 ">Annual</h1>
                <h1>Rs 599</h1>
                <h1>Save 27%</h1>
            </div>
                <GooglePayButton
    environment="TEST"
    paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
        {
            type: 'CARD',
            parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
                gateway: 'example',
                gatewayMerchantId: 'exampleGatewayMerchantId',
            },
            },
        },
        ],
        merchantInfo: {
            merchantId: '665717561011426458',
            merchantName: 'HighFives Game',
            },
            transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: '349.00',
            currencyCode: 'INR',
            countryCode: 'IN',
            },
    }}
    onLoadPaymentData={paymentRequest => {
        console.log('load payment data', paymentRequest);
    }}
    buttonColor ={'#FF6356'}
    buttonType={'short'}
    buttonSizeMode={'fill'}

    className="w-1/2"
    />
        </div>
       </div>
       </div>
    </div>
  )
}

export default Subscribe