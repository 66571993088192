import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom' 

const AllGames = (props) => {

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    console.log(query.get('msisdn'))
    
    const[games, setGames] = useState([])
    const [loader, setLoader] = useState(true)
    useEffect(() =>{
        axios.get('https://highfivesgames.com/games/',{
            headers:{
                "x-operator":query.get('op')
            }
        })
        .then((response)=>{
            setGames(response.data.games)
            setLoader(false)
        })
        .catch((err)=>{console.log(err)})
    },[])

    console.log(games)

  return (
    <div className="mx-4">
        <div className="h-scren scrollbar-hide pb-20 px-3  overfow-auto mt-4">
            <div>
                {
                    !loader 
                    ? 
                    <div>
                    <div className="grid grid-cols-2 gap-4  grid-rows-3">
                        <div  className="row-span-1">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 5 }} >
                        <img src={`https://highfivesgames.com/${games[5].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-2">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 3 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[3].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-2">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 1 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[1].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-1">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 7 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[7].new_image_path}`} className="-mt-12"></img> 
                        </Link>
                        </div>
                        </div>
                        <div className="-mt-10 grid grid-cols-2 gap-4">
                        <div  className="row-span-1 ">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 2 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[2].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-2">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 8 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[8].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-2">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 4 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[4].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-1">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 0 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[0].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        </div>
                        <div className="mt-5 grid grid-cols-2 gap-4">
                        <div  className="row-span-1 ">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 6 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[6].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-2">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 13 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[13].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        <div className="row-span-2">
                        <Link to={{
                            pathname:"/howtoplay",
                        }}
                        state={{ index: 12 }} 
                        >
                        <img src={`https://highfivesgames.com/${games[12].new_image_path}`} className=""></img> 
                        </Link>
                        </div>
                        </div>
                    </div>
                    
                    : <div>Loading</div>

                }
            </div>
        </div>
    </div>
  )
}

export default AllGames
