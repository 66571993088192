// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByZTCfslEjIBve0QDYVXG-is0T3qKG3ig",
  authDomain: "high-five-ea7be.firebaseapp.com",
  projectId: "high-five-ea7be",
  storageBucket: "high-five-ea7be.appspot.com",
  messagingSenderId: "66325948918",
  appId: "1:66325948918:web:e9df86b6469c88daf3bfd7",
  measurementId: "G-6R7CN7TTBK"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);