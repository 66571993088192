import React, {useState} from 'react'
import login from '../images/login.png'
import logo from '../images/logo.png'
import fb from '../images/fb.png'
import google from '../images/google.png'
import apple from '../images/apple.png'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import { GoogleAuthProvider, getAuth, signInWithPopup, OAuthProvider,  } from "firebase/auth";
import {app} from '../firebase'
import { useLocation } from 'react-router-dom'
const Alogin = () => {

  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const provider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');
  const auth = getAuth(app);
  auth.languageCode = 'it';

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let query = useQuery()

console.log(query.get('msisdn'))

  const googleLogin = (e)=>{
    e.preventDefault();
    signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.log(user)
    // console.log('Token: '+ token)
    axios.post('https://highfivesgames.com/users/sociallogin',{
      type:'google',
      name:user.displayName,
      email:user.email,
      uid:user.uid,
    },{
      headers:{
        'x-operator':query.get('op')
      }
    })
    .then((response)=>{
      console.log(response)
      if(response.status==204){
        alert('The user does not exist')
      }
      else{
        sessionStorage.setItem('userId',response.data.user.id)
        sessionStorage.setItem('sessionId',response.data.user.session)
        navigate('/home')
      }
    })
    .catch((err)=>{
      console.log(err)
    })
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
  }

  const appleLogin = (e) => {
    e.preventDefault();
    signInWithPopup(auth, appleProvider)
  .then((result) => {
    // The signed-in user info.
    const user = result.user;

    // Apple credential
    const credential = OAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;
    console.log(credential)

    // ...
  })
  .catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The credential that was used.
    const credential = OAuthProvider.credentialFromError(error);
    console.log(error)

    // ...
  });

  }
  const handleGuest = () => {
    axios.get('https://highfivesgames.com/users/guestlogin',{
      headers:{
        'x-operator':query.get('op')
      }
    })
    .then((response) =>{
      // console.log(response.user.id)
      sessionStorage.setItem('userId',response.data.user.id)
      sessionStorage.setItem('sessionId',response.data.user.session)
      navigate('/home')
    })
    .catch((err)=>{console.log(err)})
  }

  const handleSubmit=(e)=>{
    e.preventDefault()
    console.log(email,pass)
    const string = btoa(`${email}:${pass}`)
    const fstring = 'Basic '+ string;
    console.log(fstring)
    axios({method: 'post',url:'https://highfivesgames.com/users/login/',headers:{'authorization':fstring, 'x-operator':query.get('op')}})
    .then((response)=>{
      console.log(response)
      if(response.status === 200){
        navigate('/home')
        sessionStorage.setItem('userId',response.data.user.id)
        sessionStorage.setItem('sessionId',response.data.user.session)
      }
      else{
        alert('Wrong email id or password')
      }
    })
    .catch((error)=>{
      console.log(error)
      alert('Wrong email id or password')
    })
    setEmail('')
    setPass('')
  }
  return (
    <div className="font-poppins max-w-[30rem]  w-screen mx-auto o h-screen  bg-[#2C3F66]">
        <img src={logo} className="h-16 pl-2 pt-2"></img>
        <img src={login} className="mx-auto h-[18rem] -mt-8"></img>
        <div className="bg-[#FF6356] -pb-32 overflow- h-[30rem] -z-10 -skew-y-[18deg] mt-16 ">
          <div className="bg-white rounded-3xl shadow-3xl skew-y-[18deg] -mt-12 px-6 py-4 mx-6">
            <h1 className="text-[#2C3F66] text-center ">Sign in to your account</h1>
            <form className="mt-6">
              <label className="mt-1">Your Email</label><br></br>
              <input className="my-2 w-full border-b-2 outline-none pb-1 " type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}></input><br></br>
              <label className="mt-2">Password</label><br></br>
              <input className="my-2 w-full border-b-2 outline-none pb-1" type="password" value={pass} onChange={(e)=>{setPass(e.target.value)}}></input>
              <h1 className="text-right text-[#1991EB] mb-3 text-sm">Forgot Password?</h1>
              <div className="flex justify-between">
                <div className="flex space-x-4">
                  <button onClick={googleLogin}>
                    <img src={google} ></img>
                    </button>
                    <button onClick={appleLogin}>
                    <img src={apple} className="h-12"></img>
                    </button>
                </div>
                <button onClick={handleSubmit} className="bg-[#2C3F66] text-white px-10 -mr-6 -mb-4 rounded-l-3xl rounded-br-3xl "> 
                Sign in
                </button>
                
              </div>
            </form>
          </div>
          <div className="skew-y-[18deg] mt-4 text-white text-center">
          <button   onClick={handleGuest}>
            Login as a Guest
          </button>
          </div>
            <Link to="/signup" className="">
            <h1 className="text-center mt-8 text-white skew-y-[18deg] ">Don't have an account? Sign up</h1>
            </Link>
        </div>
        <div className="w-screen max-w-[30rem] py-20 -mt-20 bg-[#FF6356]"></div>
    </div>
  )
}

export default Alogin