import React, {useState, useEffect} from 'react'
import rubik from '../images/Rubik.png'
import axios from 'axios'
import AllGames from '../components/AllGames'
import Upcoming from '../components/Upcoming'
import { useLocation } from 'react-router-dom'
const FirstIndex = (props) => {
    const [allGames, setAllGames] = useState(0)
    const [language, setLanguage] = useState('')

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    console.log(query.get('msisdn'))

    useEffect(()=>{
        if(localStorage.getItem('language')){
            setLanguage(localStorage.getItem('language'))
          }
          else{
            setLanguage('english')
          }
    },[localStorage.getItem('language')])
    // console.log(allGames)
    // console.log(props.games)

  return (
    <div className=' mx-auto  bg-[#2C3F66]'>
        
        {/* <div className='px-4'>
        <div className="bg-no-repeat bg-center bg-contain" style={{ backgroundImage: "url(/Rectangle.png)" }}>
            <div className='py-4 flex items-center justify-evenly'>
                <div className='flex-none'>
                <img src={rubik} className='h-24'></img>
                </div>
                <div className='flex-2'>
                    <h1 className='text-2xl text-white my-2'>Today's Challenge</h1>
                    <h1 className='text-xl text-white my-2'>Points: 2,000</h1>
                </div>
            </div>
        </div>
        </div> */}
        <div className='grid grid-cols-2 sticky top-24 z-10 text-center rounded-xl text-white items-center py-1 px-1 bg-[#40468B] mx-4'>
           <button onClick={()=>{setAllGames(0)}}>
            <div className={allGames ? 'rounded-xl  py-2':'rounded-xl bg-[#FF6356] py-2'}>
                <h1 className='text-xl'>{
                    language == "english"
                    ?
                    "Play Now"
                    :
                    "العب الان"
                }</h1>
            </div>
            </button> 
            <button onClick={()=>{setAllGames(1)}}>
            <div className={allGames ? 'rounded-xl bg-[#FF6356]  py-2':'rounded-xl  py-2'}>
                <h1 className='text-xl'>{
                    language == "english"
                    ?
                    "Upcoming"
                    :
                    "القادمة"
                }</h1>
            </div>
            </button>
        </div>
        <div className="">
            {
                allGames === 1 && 
               <div>
                <Upcoming/>
               </div> 
            }
            {
                allGames === 0 &&
                <div>
                    <AllGames games={props.games}/>
                </div>
            }
        </div>
        </div>
  )
}

export default FirstIndex