import React,{useRef, useEffect} from 'react'
import splash_screen from '../images/splash_screen.mp4'
import { useNavigate } from 'react-router-dom'
import {VideoComp} from '../components/VideoComp'
const Video = () => {
    const navigate = useNavigate()
    useEffect(()=>{
      setTimeout(()=>{
        navigate('/login')
      },9000)
    })
  return (
    <div className="font-poppins max-w-[30rem] min-h-screen w-screen mx-auto bg-[#33166f]">
         <VideoComp src={splash_screen}
                   id="myVideo1"
                   muted={true}
                   autoplay={true}
            />
    </div>
  )
}

export default Video