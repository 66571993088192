import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

const Upcoming = () => {
    const[games, setGames] = useState([])
    const [loader, setLoader] = useState(true)
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    console.log(query.get('msisdn'))
    useEffect(() =>{
        axios.get('https://highfivesgames.com/games/',{
            headers:{
                'x-operator':query.get('op')
            }
        })
        .then((response)=>{
            setGames(response.data.games)
            setLoader(false)
        })
        .catch((err)=>{console.log(err)})
    },[])

    console.log(games)

  return (
    <div className="mx-4">
        <div className="pb-10 mt-4">
            <div>
                {
                    !loader 
                    ? 
                    <div>
                    <div className="grid grid-cols-2 gap-2  grid-rows-3">
                        <div  className="row-span-1">
                        <img src={`https://highfivesgames.com/${games[9].new_image_path}`} className="object-cover"></img> 
                        </div>
                        <div className="row-span-2">
                        <img src={`https://highfivesgames.com/${games[11].new_image_path}`} className="object-cover"></img> 
                        </div>
                        <div className="row-span-2">
                        <img src={`https://highfivesgames.com/${games[12].new_image_path}`} className="object-cover"></img> 
                        </div>
                        {/* <div className="row-span-1">
                        <img src={`https://highfivesgames.com/${games[0].new_image_path}`} className="object-cover -mt-12"></img> 
                        </div> */}
                    </div>
                    </div>
                    
                    : <div>Loading</div>

                }
            </div>
        </div>
    </div>
  )
}

export default Upcoming