import React, {useState} from 'react'

const ScoreboardAllTime = (props) => {
    const [leaders, getLeaders] = useState(props.list)

  return (
    <div className="bg-[#ffe9e9] py-3 px-4 relative mx-3 scrollbar-hide overflow-auto h-[30rem] my-4 rounded-2xl">
        {
            leaders.map((leader) =>{
                return (
                    <div className="p-3 flex my-3 bg-white rounded-xl items-center space-x-6">
                    <div className="border-2 border-slate-400 rounded-full px-3 py-1">
                        <h1 className="text-slate-500">{leader.rank}</h1>
                    </div>
                    <img src={`https://highfivesgames.com/${leader.user_avatar}`} className="rounded-full h-14"></img>
                    <div className='text-left '>
                        <h1 className="text-xl">{leader.user_name}</h1>
                        <h1 className="text-slate-500">{leader.score} points</h1>
                    </div>
                </div>
                );
            })
        }
    </div>
  )
}

export default ScoreboardAllTime